import styled from 'styled-components';
import Typography from 'components/UI/Typography';

export const StyledSearch = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  padding: 70px 30px;
  width: 100vw;
  height: 100%;
  background-color: ${(props) => props.theme.colors.secondary};
  z-index: 20;
  overflow: scroll;
`;

export const Title = styled(Typography)`
  font-size: ${(props) => props.theme.fonts.h1};
  line-height: 40px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const CloseButton = styled.button`
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.purple};
  cursor: pointer;
`;

export const CloseButtonLine = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 1px;
  background-color: ${(props) => props.theme.colors.secondary};
`;

export const MainContainer = styled.div`
  margin: 48px 0 52px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 52px 40px;
`;

export const Container = styled.div``;

export const SearchKeywords = styled.div`
  max-width: 550px;
`;

export const Subtitle  = styled(Typography)`
  margin-bottom: 13px;
  font-size: ${(props) => props.theme.fonts.h3};
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.primary};
`;

export const Years = styled.div`
  margin-top: 10px;
`;

export const Themes = styled.div``;

export const List = styled.ul`
  column-count: 2;
`;

export const ChildList = styled(List)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 25px;

  > .filterAccordion {
    width: calc(50% - 15px);
    margin-bottom: -1px;

    /* > .filterAccordionBody {
      padding-top: 0;
    } */

    > .filterAccordionHead {
      
      .Typography {
        color: ${props => props.theme.colors.blue};
      }

      > .filterAccordionArrow {
        background-image: url("/images/arrow-down-blue.svg");
      }
    }
  }
`

export const SubChildList = styled(List)`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > li {
    width: 100%;

    label {
      :hover {
        font-weight: bold;
      }
    }
  }
`

export const ListItem = styled.li`
  list-style-type: none;
  margin: 0;
`;

export const ButtonsContainer = styled.div`
  align-self: flex-end;
  margin-top: 60px;
`;

export const Button = styled.button`
  margin-left: 18px;
  padding: 12px 30px;
  font-size: ${(props) => props.theme.fonts.body_small};
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.secondary};
  background-color: ${(props) => props.theme.colors.purple};
  border: 1px solid ${(props) => props.theme.colors.purple};
  transition: background-color 0.3s ease;
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.purple};
  }
`;

export const ResetButton = styled.button`
  padding: 10px;
  font-size: ${(props) => props.theme.fonts.body};
  line-height: 19px;
  text-decoration-line: underline;
  background-color: ${(props) => props.theme.colors.secondary};
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue};

  img {
    margin-right: 5px;
  }
`;

export const InputContainer = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    width: 20px;
    height: 20px;
    background-image: url('/images/search.svg');
  }
`;

export const StyledInput = styled.input.attrs(props => ({ width: props.width || '100%', padding: props.padding || '20px 20px 20px 60px' }))`
  position: relative;
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  border: 1px solid ${(props) => props.theme.colors.blue};
  border-radius: 2px;
  font-size: ${(props) => props.theme.fonts.h3};
  line-height: 23px;
  letter-spacing: 1px;
  color: ${(props) => props.theme.colors.purple};

  &:focus-visible {
    outline: none;
  }

  ::placeholder {
    font-size: ${(props) => props.theme.fonts.h3};
    line-height: 23px;
    letter-spacing: 1px;
    color: ${(props) => props.theme.colors.purple};
  }
`;

export const InputPromts = styled.div`
  height: 42px;
  overflow: hidden;
  margin-top: 5px;
  font-size: ${(props) => props.theme.fonts.h4};
  cursor: pointer;
`;

export const Promt = styled.a`
  &:hover {
    color: ${(props) => props.theme.colors.purple};
    text-decoration: underline;
  }
`;

export const AccordionBlocksTitle = styled(Typography)`
  font-size: ${(props) => props.theme.fonts.h2};
  font-weight: 500;
  opacity: .5;
  cursor: not-allowed;
`

export const AccordionBlocks = styled.div`

`
