import React, { useRef, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import $ from 'jquery';
import '../../utils/styles/tutorial.css';
import { useSessionStorage } from 'react-use';
import { globalHistory } from '@reach/router'
import HandoffAnimation from '../../utils/three/main-animation/HandoffAnimation';
import tutorialFunctions from '../../utils/functions/tutorial.js'
import gtmEvents from '../../utils/functions/gtmFunctions.js';
import { Link } from 'gatsby';

const Year = (props) => {
  return (
    <li className='timeline__year'>
      <span className='timeline__sup-year'></span>
      <span className='timeline__year-mark'> {props.year} </span>
      <span className='timeline__sub-year'></span>
    </li>
  );
};

const YearList = (props) => {
  const years = props.years?.map((year) => <Year year={year} key={year} />);

  return <ul className='timeline__year-list'>{years}</ul>;
};

const Timeline = (props) => {
  const data = props?.data?.allTimelineEvents?.edges?.[0]?.node;
  const nationsItemsLength = props?.nationsItemsLength;
  const [years, setYears] = useState([]);
  const filtredEvents = props.filtredEvents;
  const filtredYears = props.filtredYears;
  const animationRef = useRef();
  const sceneRef = useRef();
  const timelineRef = useRef();
  const yearCursorRef = useRef();
  const firstScreenRef = useRef();
  const procentProgressRef = useRef();
  const arrowTopRef = useRef();
  const arrowBottomRef = useRef();
  const fullSizeImg = useRef();
  const closeBtn = useRef();
  const imgWrapper = useRef();
  const description = useRef();
  const descriptionTitle = useRef();
  const descriptionBody = useRef();
  const descriptionLink = useRef();

  const [globalAnimation, setGlobalAnimation] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [amountOfEvents, setAmountOfEvents] = useSessionStorage(
    'amount-of-events',
    null
  );

  useEffect(() => {
    gtmEvents.sendVirtualPageView("Accueil", globalHistory.location.href.href, "Accueil");
    const animation = new HandoffAnimation({
      fullData: data,
      animation: animationRef.current,
      sceneContainer: sceneRef.current,
      timeline: timelineRef.current,
      yearCursor: yearCursorRef.current,
      firstScreen: firstScreenRef.current,
      progressProcent: procentProgressRef.current,
      arrowTop: arrowTopRef.current,
      arrowBottom: arrowBottomRef.current,
      fullSizeImg: fullSizeImg.current,
      closeBtn: closeBtn.current,
      imgWrapper: imgWrapper.current,
      description: description.current,
      descriptionTitle: descriptionTitle.current,
      descriptionBody: descriptionBody.current,
      descriptionLink: descriptionLink.current,
    });

    animation.init();

    animation.addListener('load', () => {
      setYears(animation.updateYearSettings());

      setIsLoaded(true);

      props.setEarlierAndLatestEventDates(animation.getCurrentYears());
    });

    setGlobalAnimation(animation);

    return () => {
      animation.removeListener('load-progress');
      animation.removeListener('load');
      animation.destroy();
    };
  }, []);

  useEffect(() => {
    let resultOfAmountOfEvents = amountOfEvents;
    if (isLoaded && props.restartAnimation === 'true') {
      props.setRestartAnimation('false');
      const allData = globalAnimation.getAllData();

      //console.log(allData)

      if (
        filtredYears.from === '' &&
        filtredYears.to === '' &&
        !filtredEvents
      ) {
        console.log('NO EVENTS')
        setAmountOfEvents(null);
        props.getCountOfEvents(0);
        globalAnimation.restartWithNewData(allData);
      } else if (filtredYears.to !== '' || filtredYears.from !== '') {
        let data = globalAnimation.sortByYear(
          allData,
          +filtredYears.from,
          +filtredYears.to
          );

        if (!filtredEvents) {
          resultOfAmountOfEvents = data.filter(item => item['handoff-type'] !== 'blueBadges').length;
          setAmountOfEvents(resultOfAmountOfEvents);
          props.getCountOfEvents(resultOfAmountOfEvents);
          globalAnimation.restartWithNewData(data);
        } else {
          data = globalAnimation.sortById(data, filtredEvents);
          resultOfAmountOfEvents = data.filter(item => item['handoff-type'] !== 'blueBadges').length;
          setAmountOfEvents(resultOfAmountOfEvents);
          props.getCountOfEvents(resultOfAmountOfEvents);
          globalAnimation.restartWithNewData(data);
        }
      } else if (filtredEvents !== null) {

        let data = globalAnimation.sortById(allData, filtredEvents);

        resultOfAmountOfEvents = data.filter(item => item['handoff-type'] !== 'blueBadges').length;
        setAmountOfEvents(resultOfAmountOfEvents);
        props.getCountOfEvents(resultOfAmountOfEvents);
        globalAnimation.restartWithNewData(data);
      }

      setYears(globalAnimation.updateYearSettings());
    }
  }, [filtredEvents, filtredYears, isLoaded]);

  const linkClick = () => {
    navigate(descriptionLink.current.href);
  };

  const nationDropdownActivated = false;

  return (
    <div>
      <div
        className='timeline-animation__full-size-img full-size-img'
        ref={fullSizeImg}
      >
        <button
          type='button'
          style={{ cursor: 'pointer' }}
          className='full-size-img__close-btn'
          ref={closeBtn}
        >
          <span></span>
          <span></span>
        </button>
        <div className='full-size-img__img-wrapper' ref={imgWrapper}></div>

        <div className='full-size-img__description' ref={description}>
          <div
            className='full-size-img__description-title'
            ref={descriptionTitle}
          ></div>
          <div
            className='full-size-img__description-body'
            ref={descriptionBody}
          ></div>
          <a
            href='#'
            className='full-size-img__description-link'
            onClick={linkClick}
            ref={descriptionLink}
          ></a>
        </div>
      </div>
      <div className='timeline-animation' ref={animationRef}>
        <div className='timeline-animation__first-screen' ref={firstScreenRef}>
          <div className='timeline-animation__progress'>
            <span
              className='timeline-animation__progress-procent'
              ref={procentProgressRef}
            >
              1
            </span>
            %
          </div>
          <div>
            <h1 style={{ overflow: 'hidden' }}>
              {nationsItemsLength > 0 && nationDropdownActivated ? "Peuples autochtones" : "Les Lignes du temps du Québec"}
              {nationsItemsLength > 0 && nationDropdownActivated ? (
                <span
                  style={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    marginLeft: '200px',
                  }}
                  onClick={props.openFilterByNations}
                >
                </span>
              ) : <></>}
            </h1>
            {!nationDropdownActivated ? (
              <Link
                to={'/a-propos'} 
                style={{
                  fontSize: '250px',
                  marginTop: '100px',
                  color: '#7E8BC3',
                  textDecoration: 'underline',
                }}>
                À propos
              </Link>
            ) : <></>}
            {!nationDropdownActivated ? (
              <span onClick={() => tutorialFunctions.addTutorial()}
                style={{
                  fontSize: '250px',
                  marginTop: '100px',
                  marginLeft: '250px',
                  cursor: 'pointer',
                  color: '#7E8BC3',
                  textDecoration: 'underline',
                }}>
                Tutoriel
              </span>
            ) : <></>}
          </div>

        </div>

        <div className='timeline-animation__arrows'>
          <button
            type='button'
            className='timeline-animation__arrow-top'
            ref={arrowTopRef}
          >
            <svg
              width='42'
              height='69'
              viewBox='0 0 42 69'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M21.5 67.6579L21.5 3.3421'
                stroke='#5E6EB4'
                strokeWidth='2'
                strokeLinecap='square'
              />
              <path
                d='M40.5063 21.732L21 2.48699L1.49356 21.732'
                stroke='#5E6EB4'
                strokeWidth='2'
              />
            </svg>
          </button>

          <button
            type='button'
            className='timeline-animation__arrow-bottom'
            ref={arrowBottomRef}
          >
            <svg
              width='42'
              height='69'
              viewBox='0 0 42 69'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M21.5 1.34211L21.5 65.6579'
                stroke='#5E6EB4'
                strokeWidth='2'
                strokeLinecap='square'
              />
              <path
                d='M40.5063 47.268L21 66.513L1.49356 47.268'
                stroke='#5E6EB4'
                strokeWidth='2'
              />
            </svg>
          </button>
        </div>

        <div
          className='timeline-animation__scene-container'
          ref={sceneRef}
        ></div>

        <div
          className='timeline-animation__timeline timeline'
          ref={timelineRef}
        >
          <div className='timeline__current-year'>
            <div className='timeline__year-cursor' ref={yearCursorRef}></div>
          </div>

          <YearList years={years} />
        </div>
      </div>
    </div>
  );
};

Timeline.propTypes = {};

export default Timeline;
