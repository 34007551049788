import styled from 'styled-components';

export const StyledCheckboxItem = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  font-size: ${(props) => props.theme.fonts.h4};
  line-height: 24px;
  font-weight: 400;
  cursor: pointer;
  user-select: none;
`;

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkmark {
    background-color: ${(props) => props.theme.colors.purple};
    background-image: url('/images/mark.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid ${(props) => props.theme.colors.purple};
`;