export default class Timeline {
  constructor(props) {
    this.generalManager = props.generalManager;
  }

  tick() {
    this.setYearCursor();
  }

  setYearCursor() {
    this.generalManager.DOM.yearCursor.style.bottom = `${this.generalManager.stage.currentProcent}%`;
  }

  resize() {
   return this; 
  }
}