import React, { useMemo, useEffect, useState } from 'react';
import {
  StyledSearch,
  Title,
  CloseButton,
  CloseButtonLine,
  MainContainer,
  List,
  ChildList,
  SubChildList,
  ListItem,
  Themes,
  Container,
  SearchKeywords,
  Subtitle,
  Years,
  ResetButton,
  Button,
  ButtonsContainer,
  InputContainer,
  StyledInput,
  InputPromts,
  Promt,
  AccordionBlocksTitle,
  AccordionBlocks,
} from './styles.js';
import FilterAccordion from './components/FilterAccordion/index.js';
import CheckboxItem from './components/CheckboxItem/index.js';

const Search = ({
  data,
  isOpen,
  closeSearch,
  handleOnSearchClick,
  setFiltredThemes,
  setFiltredNations,
  setFiltredRegions,
  setFilteredCompetences,
  filtredThemes,
  filtredNations,
  filtredRegions,
  filteredCompetences,
  keywordInput,
  yearFrom,
  yearTo,
  keywords,
  setYearTo,
  setYearFrom,
  setKeywordInput,
  handleOnResetClick,
  earlierAndLatestEventDates,
  getFiltredThemes,
  getFiltredNations,
  getFiltredRegions,
  getFilteredCompetences,
  title = { title: 'Chercher un événement' },
  subtitles = {
    keywords: 'Mots-clés',
    years: 'Années',
    themes: 'Thèmes',
    nations: 'Peuples autochtones (à venir)',
    regions: 'Régions administratives',
    domaines: 'Domaines d’apprentissage (à venir)',
    disciplines: 'Disciplines',
    competences: 'Compétences',
  },
}) => {
  const themes = data.thematiques || [];
  const regions = data.regions || [];
  const nations = data.nations || [];
  const domaines = data.domaines || [];
  const disciplines = data.disciplines || [];
  const competences = data.competences || [];
  const DDC = [...domaines, ...disciplines, ...competences];
  const [params, setParams] = useState(null);
  const [paramsParsing, setParamsParsing] = useState(null);

  const multipleKeywords = (keywordInput) => {
    if (keywordInput.includes(',')) {
      const keywordsResult = keywordInput
        .split(',')
        .map((item) => item.trim())
        .filter((item) => item !== '');
      return keywordsResult;
    } else {
      return [keywordInput];
    }
  };

  const inputPrompts = useMemo(() => {
    function sortKeywords(array, str) {
      const convertHtmlToText = (html) => {
        const p = document.createElement('p');
        p.innerHTML = html;
        return p.innerText;
      }

      const specialCharacters = (string) => {
        let newStr = string;
        if (string.includes('\'') || string.includes('`') || string.includes('’')) {
          newStr = string.replace(/'|`|’/g, '');
        }

        return newStr.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      }

      return array.flatMap(
        (item) => {
          const searchStr = specialCharacters(str.trim().toLowerCase());
          let result = [];

          if (item.terme) {
            const terme = item.terme;
            if (~specialCharacters(terme).trim().toLowerCase().indexOf(searchStr)) result = [...result, terme];
          }
          if (item.Title) {
            const title = convertHtmlToText(item.Title);
            if (~specialCharacters(title).trim().toLowerCase().indexOf(searchStr)) result = [...result, title];
          }
          if (item.ImageCredit ) {
            const imageCredit = convertHtmlToText(item.ImageCredit);
            if (~specialCharacters(imageCredit).trim().toLowerCase().indexOf(searchStr)) result = [...result, imageCredit];
          }
          if (item.personnages) {
            const personnages = item.personnages.filter(i =>
              ~specialCharacters(convertHtmlToText(i.terme).trim().toLowerCase()).indexOf(searchStr)
            );
            if (personnages.length) {
              result = [...result, convertHtmlToText(personnages[0].terme)];
            }
          }

          return result;
        }
      ).filter((value, index, self) => self.indexOf(value) === index);
    }

    if (keywordInput === '') {
      return [];
    } else {
      const keywordsResult = multipleKeywords(keywordInput);
      const result = sortKeywords(
        keywords,
        keywordsResult[keywordsResult.length - 1]
      );
      if (result.length > 20) {
        return result.slice(0, 19);
      }
      return result;
    }
  }, [keywords, keywordInput]);

  function handlePromtOnClick(event, string) {
    event.preventDefault();

    if (keywordInput.includes(',')) {
      const index = keywordInput.lastIndexOf(',');

      if (index === keywordInput.length - 1) {
        const newValue = keywordInput.slice(0, keywordInput.length - 1);
        if (newValue.includes(',')) {
          const index = newValue.lastIndexOf(',');
          const result = newValue.slice(0, index);
          setKeywordInput(result + ', ' + string);
        } else {
          setKeywordInput(string);
        }
      } else {
        const result = keywordInput.slice(0, index);
        setKeywordInput(result + ', ' + string);
      }
    } else {
      setKeywordInput(string);
    }
  }

  const handleSearchParams = () => {

    const activeFilters = [
      filtredRegions,
      filtredNations,
      filtredThemes,
      filteredCompetences,
      keywordInput,
      yearFrom,
      yearTo
    ].flat().filter((item) => item !== '')

    let url = new URL(window.location.href)
    let searchQuery = new URLSearchParams(url.search)

    if (url.search && !activeFilters?.length) {
      const searchQueryArray = Array.from(searchQuery.entries());
      const searchQueryObject = searchQueryArray.reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
      setParams(searchQueryObject)
    }
  }

  useEffect(() => {
    handleSearchParams()
  }, [])

  const callSearchOnEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      document.querySelector('.js-search-trigger-btn').click();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', callSearchOnEnterKeyPress);
    }

    return () => {
      document.removeEventListener('keydown', callSearchOnEnterKeyPress);
    }
  }, [isOpen])

  useEffect(() => {
    if (params && !isOpen) {
      const {
        region: paramRegion,
        nation: paramNation,
        theme: paramTheme,
        competence: paramCompetence,
        keyword: paramKeyword,
        year_from: paramYearFrom,
        year_to: paramYearTo,
      } = params;

      const applyFilterFromParams = (data, param, state) => {

        let paramArray = param;

        let payload = [
          data.find((d) => d.terme.includes(paramArray))
        ]

        if (state?.get?.length > 0) {
          payload = [
            ...state.get,
            data.find((d) => d.terme.includes(paramArray))
          ]
        }
      
        state.set(payload);

        if (paramArray.includes(',')) {
          paramArray = param.split(',');

          const match = data.filter(d => paramArray.includes(d.terme))

          payload = [...match]
  
          if (state?.get?.length > 0) {
            payload = [...state.get, ...match]

            state.set(payload);
          } else {
            state.set(payload);
          }
        }

      }

      if (paramRegion) {
        applyFilterFromParams(regions, paramRegion, {
          get: filtredRegions,
          set: setFiltredRegions,
        });
      }
      if (paramNation) {
        applyFilterFromParams(nations, paramNation, {
          get: filtredNations,
          set: setFiltredNations,
        });
      }
      if (paramTheme) {
        applyFilterFromParams(themes, paramTheme, {
          get: filtredThemes,
          set: setFiltredThemes,
        });
      }
      if (paramCompetence) {
        applyFilterFromParams(DDC, paramCompetence, {
          get: filteredCompetences,
          set: setFilteredCompetences,
        });
      }
      if (paramKeyword) {        
        let keywordsArray = paramKeyword;

        if (paramKeyword.includes(',')) {
          keywordsArray = paramKeyword.split(',');
        }

        setKeywordInput(keywordsArray);
      }
      if (paramYearFrom) {
        setYearFrom(paramYearFrom);
      }
      if (paramYearTo) {
        setYearTo(paramYearTo);
      }

      setParamsParsing(true);
    }
  }, [params])

  useEffect(() => {
    if (paramsParsing && params && !isOpen) {
      handleOnSearchClick();
      setParamsParsing(false);
    }
  }, [paramsParsing]);

  return (
    <>
      {isOpen ? (
        <StyledSearch>
          {title?.title && <Title>{title.title}</Title>}
          <CloseButton type='button' onClick={() => closeSearch()}>
            <CloseButtonLine
              style={{
                transform:
                  'translateX(-50%) translateY(-50%) rotate(45deg) translateZ(0px)',
              }}
            />
            <CloseButtonLine
              style={{
                transform:
                  'translateX(-50%) translateY(-50%) rotate(135deg) translateZ(0px)',
              }}
            />
          </CloseButton>

          <MainContainer>
            <Container>
              <SearchKeywords>
                {subtitles?.keywords && (
                  <Subtitle>{subtitles.keywords}</Subtitle>
                )}
                <InputContainer>
                  <StyledInput
                    onChange={(event) => {
                      setKeywordInput(event.target.value)
                    }}
                    type='text'
                    placeholder='Lancer une recherche'
                    value={keywordInput}
                  ></StyledInput>
                  <InputPromts>
                    {inputPrompts.map((prompt, index) => {
                      return (
                        <Promt
                          href='#'
                          onClick={(event) => handlePromtOnClick(event, prompt)}
                        >
                          {index === inputPrompts.length - 1 ? (
                            prompt
                          ) : (
                            <span>{prompt},&nbsp;</span>
                          )}
                        </Promt>
                      );
                    })}
                  </InputPromts>
                </InputContainer>
              </SearchKeywords>

              <Years>
                <Subtitle>{subtitles?.years && subtitles.years}</Subtitle>
                <StyledInput
                  onChange={(event) => setYearFrom(event.target.value)}
                  onBlur={(event) => {
                    if (event.target.value < earlierAndLatestEventDates.first) {
                      setYearFrom(earlierAndLatestEventDates.first)
                    } else if (event.target.value > earlierAndLatestEventDates.last) {
                      setYearFrom(earlierAndLatestEventDates.last)
                    }
                  }}
                  type='number'
                  min={earlierAndLatestEventDates.first}
                  max={earlierAndLatestEventDates.last}
                  placeholder={earlierAndLatestEventDates.first}
                  value={yearFrom}
                  width='90px'
                  padding='12px 9px'
                ></StyledInput>

                <span style={{ margin: '0 12px', fontSize: '20px' }}>à</span>

                <StyledInput
                  onChange={(event) => setYearTo(event.target.value)}
                  onBlur={(event) => {
                    if (event.target.value < earlierAndLatestEventDates.first) {
                      setYearFrom(earlierAndLatestEventDates.first)
                    } else if (event.target.value > earlierAndLatestEventDates.last) {
                      setYearFrom(earlierAndLatestEventDates.last)
                    }
                  }}
                  type='number'
                  placeholder={earlierAndLatestEventDates.last}
                  max={earlierAndLatestEventDates.last}
                  min={earlierAndLatestEventDates.first}
                  value={yearTo}
                  width='90px'
                  padding='12px 9px'
                ></StyledInput>
              </Years>
            </Container>

            <Themes>
              <Subtitle>{subtitles?.themes && subtitles.themes}</Subtitle>
              <List>
                {themes.map((theme) => {
                  return (
                    <ListItem key={theme.id}>
                      <CheckboxItem
                        item={theme}
                        handleOnChange={getFiltredThemes}
                        isChecked={
                          !!filtredThemes.find((item) => item?.id === theme?.id)
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Themes>

            {/* L'histoire du Québec selon… */}
            <FilterAccordion
              title={subtitles.nations}
              counter={filtredNations.length}
              isDisable={true}
              children={
                <List>
                  {nations.map((nation) => {
                    return (
                      <ListItem key={nation.id}>
                        <CheckboxItem
                          item={nation}
                          handleOnChange={getFiltredNations}
                          isChecked={
                            !!filtredNations.find(
                              (item) => item?.id === nation?.id
                            )
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              }
            ></FilterAccordion>

            {/* Regions Administratives */}
            <FilterAccordion
              title={subtitles.regions}
              counter={filtredRegions.length}
              children={
                <List>
                  {regions.map((region) => {
                    return (
                      <ListItem key={region?.id}>
                        <CheckboxItem
                          item={region}
                          handleOnChange={getFiltredRegions}
                          isChecked={
                            !!filtredRegions.find(
                              (item) => item?.id === region?.id
                            )
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              }
            ></FilterAccordion>


          </MainContainer>

          {/* DDC */}

          <AccordionBlocks>

            <AccordionBlocksTitle as="h3" style={{ marginBottom: '25px' }}>{subtitles.domaines}</AccordionBlocksTitle>
            {/*{data.DDC.map((item) => {*/}
            {/*  return item.DOMAINES.map((domaine) => {*/}
            {/*    const selectedCompetences = []*/}
            {/*    let counter = 0*/}

            {/*    domaine?.DISCIPLINES.forEach(discipline => {*/}
            {/*      if (discipline?.COMPETENCES?.length > 0) {*/}
            {/*        selectedCompetences.push(...discipline?.COMPETENCES)*/}
            {/*      }*/}
            {/*    })*/}

            {/*    const checkedCompetences = selectedCompetences.filter(competence => {*/}
            {/*      return filteredCompetences.find(item => item?.id === competence?.id)*/}
            {/*    })*/}

            {/*    counter = checkedCompetences.length*/}

            {/*    return (*/}
            {/*      <FilterAccordion*/}
            {/*        key={domaine.id}*/}
            {/*        title={domaine.terme}*/}
            {/*        counter={counter}*/}
            {/*        isDisable={true}*/}
            {/*        children={*/}
            {/*          <ChildList className='ChildListLevel1'>*/}
            {/*            {domaine.DISCIPLINES.map((disc) => {*/}
            {/*              return (*/}
            {/*                <FilterAccordion*/}
            {/*                  key={disc.id}*/}
            {/*                  title={disc.terme}*/}
            {/*                  counter={disc?.COMPETENCES?.length}*/}
            {/*                  children={*/}
            {/*                    <SubChildList className='ChildListLevel2'>*/}
            {/*                      {disc?.COMPETENCES?.map((competence) => {*/}
            {/*                        competence = {*/}
            {/*                          ...competence,*/}
            {/*                          evenements: DDC.filter(item => item.id === competence.id)?.flatMap(item => item.evenements),*/}
            {/*                        }*/}

            {/*                        return (*/}
            {/*                          <ListItem key={competence?.id}>*/}
            {/*                            <CheckboxItem*/}
            {/*                              item={competence}*/}
            {/*                              handleOnChange={getFilteredCompetences}*/}
            {/*                              isChecked={*/}
            {/*                                !!filteredCompetences.find(*/}
            {/*                                  (item) => item.id === competence.id*/}
            {/*                                )*/}
            {/*                              }*/}
            {/*                            />*/}
            {/*                          </ListItem>*/}
            {/*                        );*/}
            {/*                      })}*/}
            {/*                    </SubChildList>*/}
            {/*                  }*/}
            {/*                />*/}
            {/*              )*/}
            {/*            })}*/}
            {/*          </ChildList>*/}
            {/*        }*/}
            {/*      ></FilterAccordion>*/}
            {/*    );*/}
            {/*  });*/}
            {/*})}*/}

          </AccordionBlocks>

          <ButtonsContainer>
            <ResetButton type='button' onClick={handleOnResetClick}>
              <img alt='restart icon' src='/images/restart.svg' />
              Réinitialiser les filtres
            </ResetButton>

            <Button type='button' className="js-search-trigger-btn" onClick={handleOnSearchClick}>
              Rechercher
            </Button>
          </ButtonsContainer>
        </StyledSearch>
      ) : (
        <></>
      )}
    </>
  );
};

export default Search;
