import React, { useEffect, useState } from 'react';
import {
  StyledFilteredSearchInfo,
  ButtonSearch,
  ButtonSearchSmall,
  StyledResetButton,
  Container,
  StyledCopyURL,
  TitlesContainer,
  FiltredInfo,
} from './styles.js'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const FilteredSearchInfo = ({
  openSearch,
  handleOnResetClick,
  countOfEvents,
  filteredEventsTitles,
  earlierAndLatestEventDates,
}) => {
  const [copied, setCopied] = useState({
    copied: false,
  });
  const countOfFiltersActivated = filteredEventsTitles.length;
  const isLoaded = earlierAndLatestEventDates === null;

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 3000);
    }
  }, [copied])

  if (isLoaded) {
    return <></>
  }

  return (
    <StyledFilteredSearchInfo>
      {
        !countOfFiltersActivated
          ? <ButtonSearch type="button" onClick={() => openSearch()}>
              <img style={{marginRight: '20px', marginBottom: '2px'}} src="/images/search.svg" slt="search icon"/>
              Chercher
            </ButtonSearch>
          : <FiltredInfo>
              <ButtonSearchSmall onClick={() => openSearch()} type="button"></ButtonSearchSmall>
              <Container>
                <p>
                  <span style={{fontWeight: '700', paddingRight: '5px'}}>{countOfFiltersActivated}</span>
                  {countOfFiltersActivated === 1 ? "filtre activé" : "filtres activés"}
                </p>

                <p>
                  <span style={{fontWeight: '700', paddingRight: '5px'}}>{countOfEvents}</span>
                  {countOfEvents === 1 ? "événement" : "événements"}
                </p>

                <StyledResetButton type="button" onClick={handleOnResetClick}>
                  <img alt="restart icon" src="/images/restart.svg"/>
                  Réinitialiser les filtres
                </StyledResetButton>

                <CopyToClipboard text={window?.location?.href || ''}
                  onCopy={() => setCopied(true)}>
                  <StyledCopyURL type="button">
                    <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ContentCopyIcon"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path></svg>
                    {copied ? "Recherche copiée" : "Copier la recherche"}
                  </StyledCopyURL> 
                </CopyToClipboard>
              </Container>

              <TitlesContainer>
                {+countOfEvents === 0
                  ? <span style={{display: 'block'}}>Aucun résultat.<br/>Veuillez modifier vos filtres de recherche.</span>
                  : 
                  <>
                    {filteredEventsTitles.sort().slice(0, 2).map((title, index) => {
                        return <span key={index} style={{display: 'block'}}>{title}</span>
                    })}
                    {filteredEventsTitles.length>=3 && <span>...</span>}
                  </>
                }
              </TitlesContainer>
            </FiltredInfo>
      }
    </StyledFilteredSearchInfo>
  )
};

export default FilteredSearchInfo;