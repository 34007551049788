/* eslint no-param-reassign: ["error", { "props": false }] */
import * as THREE from 'three';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';

function lerp(value1, value2, amount1) {
  let amount = amount1;
	amount = amount < 0 ? 0 : amount;
	amount = amount > 1 ? 1 : amount;
	return value1 + (value2 - value1) * amount;
};

function easeInOutCubic(x) {
  return x < 0.5 ? 4 * x * x * x : 1 - ((-2 * x + 2) ** 3) / 2;
}

export default class Scene {
  constructor(props) {
    this.generalManager = props.generalManager;

    this.camera = new THREE.PerspectiveCamera(70, this.generalManager.width / this.generalManager.height, 1, 100);
    this.camera.position.z = -this.generalManager.stage.currentPosition + this.generalManager.settings.focusDistance;
    this.camera.position.y = 50;

    this.scene = new THREE.Scene();

    this.renderer = new CSS3DRenderer();
    this.renderer.setSize(this.generalManager.width, this.generalManager.height);
    this.generalManager.DOM.sceneContainer.appendChild( this.renderer.domElement );
    
    this.group =  new THREE.Group();

    this.scene.add( this.group );

    this.updateCameraSettings();

    const footer = document.createElement( 'div' );
    footer.className = 'timeline-animation__footer';
    this.footerElement = new CSS3DObject( footer );
    this.footerElement.position.x = this.camera.position.x;
    this.footerElement.position.y = this.camera.position.y - 600;
    this.footerElement.position.z = this.camera.position.z - 10;
    this.footerElement.rotation.x = 10 * (Math.PI / 180);
    this.scene.add(this.footerElement);

    this.firstScreenElement = new CSS3DObject(this.generalManager.DOM.firstScreen);
    this.firstScreenElement.position.x = 0;
    this.firstScreenElement.position.y = 100;
    this.firstScreenElement.position.z = -10;
    this.group.add(this.firstScreenElement);
  }

  tick() {
    this.render();
    this.updateCameraSettings(); // it should be removed in prod | it is for dat.gui now
    this.setCameraDistance();

    this.innertRotation();
  }

  innertRotation() {
    this.camera.rotation.x += 0.05 * (this.generalManager.stage.mouseY / (Math.PI * 180 * 6 / this.generalManager.settings.focusDistance) - (this.camera.rotation.x));
    this.camera.rotation.y += 0.05 * (this.generalManager.stage.mouseX / (Math.PI * 180 * 6 / this.generalManager.settings.focusDistance) - (this.camera.rotation.y));

    this.camera.position.x += 0.05 * (-this.generalManager.stage.mouseX * 50 - this.camera.position.x);
    this.camera.position.y += 0.05 * (this.generalManager.stage.mouseY * 50 - this.camera.position.y + 50);
  }

  setCameraDistance() {
    this.camera.position.z = -this.generalManager.stage.currentPosition + this.generalManager.settings.focusDistance;

    this.generalManager.settings.focusDistance = lerp(1.1, 10, easeInOutCubic(this.generalManager.stage.currentPosition/ 10));
    this.generalManager.settings.speed = lerp(0.005, 0.01, easeInOutCubic(this.generalManager.stage.currentPosition/ 10));


    this.footerElement.position.z = this.camera.position.z - 10;
  }

  render() {
    this.renderer.render( this.scene, this.camera );
  }

  setCameraFov() {
    const halfFov = Math.atan( (this.generalManager.height / 2) / this.generalManager.settings.focusDistance );
    const fov = halfFov * 2;
    const fovInDegrees = fov * 180 / Math.PI;
    this.camera.fov = fovInDegrees;
  }

  updateCameraSettings() {
    this.setCameraFov();
    this.renderer.setSize( this.generalManager.width, this.generalManager.height);
    this.camera.aspect = this.generalManager.width / this.generalManager.height;
    this.camera.updateProjectionMatrix();
  }

  resize() {
    this.updateCameraSettings();
  }
}