import React from 'react';
import {
  StyledFilterAccordion,
  Head,
  Title,
  Container,
  Body,
  Button,
  StyledFilterAccordionButton,
} from './styles.js'

const FilterByNations = ({
  children,
  title,
  handleOnSearchClick,
  isOpenFilterByNations,
  closeFilterByNations,
}) => {

  const handleOnClick = () => {
    handleOnSearchClick()
    closeFilterByNations();
  }

  return (
    <>
      {isOpenFilterByNations
        ? <StyledFilterAccordion>
            <Head className="filterAccordionHead">
              <Container>
                <Title>{title}</Title>
              </Container>

              <Button className='full-size-img__close-btn' type="button" onClick={handleOnClick}>
                <span></span>
                <span></span>
              </Button>
            </Head>

            <Body>
              {children}
            </Body>

            <StyledFilterAccordionButton onClick={handleOnClick}>
              Chercher
            </StyledFilterAccordionButton>
          </StyledFilterAccordion>
        : <></>
      }
    </>
  )
}

export default FilterByNations;